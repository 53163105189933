import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import GalleryEntry from 'components/Gallery/Entry'

const availableMocks = {
  cashblock: { name: 'Cashblock', url: 'https://cashblock.pl' },
  clickdonate: { name: 'ClickDonate', url: 'https://clickdonate.me' },
  clicksell: { name: 'ClickSell', url: 'https://clicksell.pl' },
  cryptonomy: { name: 'Cryptonomy', url: 'https://cryptonomy.pl' },
  csgoanalytics: { name: 'CSGOAnalytics', url: 'https://csgoanalytics.com' },
  csgocrafter: { name: 'CSGOCrafter', url: 'https://csgocrafter.com' },
  csgofliper: { name: 'CSGOFliper', url: 'https://csgofliper.com' },
  csgostamp: { name: 'CSGOStamp', url: 'https://csgostamp.com' },
  csgostone: { name: 'CSGOStone', url: 'https://csgostone.com' },
  czynnaniedziela: {
    name: 'Czynna Niedziela',
    url:
      'https://play.google.com/store/apps/details?id=pl.karermedia.czynnaniedziela'
  },
  devgaming: { name: 'devGaming', url: 'https://devgaming.pl' },
  diceskins: { name: 'Dice Skins', url: 'https://diceskins.com' },
  dreamcars: { name: 'Dream Cars', url: 'https://dreamcars-rent.pl' },
  fortnitejoker: { name: 'Fortnite Joker', url: 'https://fortnitejoker.com' },
  gaben: { name: 'Gaben', url: 'https://gaben.pl' },
  goodtimedayspa: {
    name: 'Good Time Day SPA',
    url: 'https://goodtimedayspa.eu'
  },
  hypeplug: { name: 'HypePlug', url: 'https://hypeplug.pl' },
  istand: { name: 'istand.nu', url: 'https://istand.nu' },
  japaczka: { name: 'Ja, Paczka!', url: 'https://japaczka.pl' },
  minecraftrazem: { name: 'Minecraft Razem', url: 'https://minecraftrazem.pl' },
  nlhc: { name: 'NLHC', url: 'https://nlhc.pl' },
  paragraf99: { name: 'Paragraf99', url: 'https://paragraf99.pl' },
  showit: { name: 'show-it', url: 'https://show-it.tv' },
  sjakstreetwear: {
    name: 'sjakstreetwear',
    url:
      'https://play.google.com/store/apps/details?id=pl.karermedia.sjakstreetwear'
  },
  spare: { name: 'Spare', url: 'https://spareofficial.com' },
  spinnerstrike: { name: 'SpinnerStrike', url: 'https://spinnerstrike.com' },
  streetstock: { name: 'Street Stock', url: 'https://streetstock.pl' },
  streetstock_app: {
    name: 'Street Stock App',
    url:
      'https://play.google.com/store/apps/details?id=pl.karermedia.streetstock'
  },
  sub4sub: { name: 'Sub4sub', url: 'https://sub4sub.yt' },
  unbrandead: { name: 'Unbrandead Wear', url: 'https://unbrandeadwear.com' },
  uwiugi: { name: 'UWI-UGI', url: 'https://uwi-ugi.com' },
  xenostale: { name: "Xeno's Tale", url: 'https://xenostale.pl' }
}

const groupedMocks = {
  [undefined]: [
    'cashblock',
    'clickdonate',
    'clicksell',
    'cryptonomy',
    'csgoanalytics',
    'csgocrafter',
    'csgofliper',
    'csgostamp',
    'csgostone',
    'czynnaniedziela',
    'devgaming',
    'diceskins',
    'dreamcars',
    'fortnitejoker',
    'gaben',
    'goodtimedayspa',
    'hypeplug',
    'istand',
    'japaczka',
    'minecraftrazem',
    'nlhc',
    'paragraf99',
    'showit',
    'sjakstreetwear',
    'spare',
    'spinnerstrike',
    'streetstock',
    'streetstock_app',
    'sub4sub',
    'unbrandead',
    'uwiugi',
    'xenostale'
  ],
  home: [
    'fortnitejoker',
    'dreamcars',
    'showit',
    'czynnaniedziela',
    'sjakstreetwear',
    'streetstock_app'
  ]
}

const Portfolio = ({ mode }) => {
  const mocks = groupedMocks[mode]

  return (
    <StaticQuery
      query={query}
      render={data => (
        <Fragment>
          {mocks.map(key => (
            <GalleryEntry key={key} className="col-6 col-md-4">
              <a href={availableMocks[key].url} target="_blank">
                <Img
                  fluid={data[key].childImageSharp.fluid}
                  alt={availableMocks[key].name}
                />
              </a>
            </GalleryEntry>
          ))}
        </Fragment>
      )}
    />
  )
}

const query = graphql`
  query {
    cashblock: file(relativePath: { eq: "portfolio/cashblock.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clickdonate: file(relativePath: { eq: "portfolio/clickdonate.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clicksell: file(relativePath: { eq: "portfolio/clicksell.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cryptonomy: file(relativePath: { eq: "portfolio/cryptonomy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    csgoanalytics: file(relativePath: { eq: "portfolio/csgoanalytics.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    csgocrafter: file(relativePath: { eq: "portfolio/csgocrafter.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    csgofliper: file(relativePath: { eq: "portfolio/csgofliper.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    csgostamp: file(relativePath: { eq: "portfolio/csgostamp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    csgostone: file(relativePath: { eq: "portfolio/csgostone.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    czynnaniedziela: file(
      relativePath: { eq: "portfolio/czynnaniedziela.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    devgaming: file(relativePath: { eq: "portfolio/devgaming.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    diceskins: file(relativePath: { eq: "portfolio/diceskins.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dreamcars: file(relativePath: { eq: "portfolio/dreamcars.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fortnitejoker: file(relativePath: { eq: "portfolio/fortnitejoker.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gaben: file(relativePath: { eq: "portfolio/gaben.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    goodtimedayspa: file(relativePath: { eq: "portfolio/goodtimedayspa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hypeplug: file(relativePath: { eq: "portfolio/hypeplug.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    istand: file(relativePath: { eq: "portfolio/istand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    japaczka: file(relativePath: { eq: "portfolio/japaczka.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    minecraftrazem: file(relativePath: { eq: "portfolio/minecraftrazem.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nlhc: file(relativePath: { eq: "portfolio/nlhc.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    paragraf99: file(relativePath: { eq: "portfolio/paragraf99.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    showit: file(relativePath: { eq: "portfolio/showit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sjakstreetwear: file(relativePath: { eq: "portfolio/sjakstreetwear.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spare: file(relativePath: { eq: "portfolio/spare.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spinnerstrike: file(relativePath: { eq: "portfolio/spinnerstrike.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    streetstock: file(relativePath: { eq: "portfolio/streetstock.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    streetstock_app: file(
      relativePath: { eq: "portfolio/streetstock_app.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sub4sub: file(relativePath: { eq: "portfolio/sub4sub.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    unbrandead: file(relativePath: { eq: "portfolio/unbrandead.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    uwiugi: file(relativePath: { eq: "portfolio/uwiugi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    xenostale: file(relativePath: { eq: "portfolio/xenostale.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Portfolio
