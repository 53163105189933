import React from 'react'
import Helmet from 'react-helmet'
import { Container, Row } from 'reactstrap'

import Layout from 'layouts/static'

import Main from 'components/Main'
import * as CompanyMark from 'components/CompanyMark'
import Heading from 'components/Heading'
import H2 from 'components/H2'
import H4 from 'components/H4'
import Portfolio from 'components/Portfolio'
import ContactWidget from 'components/ContactWidget'

import Wrapper from 'views/portfolio/Wrapper'

const ContactPage = () => {
  return (
    <Layout variant="secondary" particlesVariant="primary">
      <Helmet title="Portfolio">
        <meta
          name="description"
          content="Nowoczesne, responsywne, zwracające na siebie pozytywną uwagę. Nasze projekty mówią same za siebie. Zobacz, nad czym ostatnio pracowaliśmy i z czego jesteśmy dumni."
        />
      </Helmet>

      <Main>
        <Wrapper>
          <Heading noBackground>
            <H4>
              <CompanyMark.Left /> Portfolio <CompanyMark.Right />
            </H4>
            <H2 className="text-xl-left">Zobacz nasze ostatnie realizacje</H2>
            <Container>
              <Row>
                <div className="col-12 col-md-10 mx-auto my-5">
                  Nowoczesne, responsywne, zwracające na siebie pozytywną uwagę?
                  <br />
                  Nasze projekty mówią same za siebie. Zobacz, nad czym ostatnio
                  <br />
                  pracowaliśmy i z czego jesteśmy dumni.
                </div>
              </Row>
            </Container>
          </Heading>

          <Container>
            <Row>
              <Portfolio />
            </Row>
          </Container>

          <ContactWidget title="Kontakt z nami" subtitle="Współpraca" />
        </Wrapper>
      </Main>
    </Layout>
  )
}

export default ContactPage
