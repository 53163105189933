import styled from 'styled-components'
import { Container } from 'reactstrap'

import media from 'styles'

import Background from 'images/portfolio_particles.png'

export default styled(Container).attrs({ fluid: true })`
  background: url(${ Background }) no-repeat left center;
  padding: 200px 0 0 0;
  font-size: 18px;

  ${ media.lessThan('lg')`
    padding-top: 50px;
  ` }
`
