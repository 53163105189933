import styled from 'styled-components'

export default styled.div`
  margin-bottom: 30px;
  overflow: hidden;

  a {
    display: block;
    position: relative;
  }

  > img {
    width: 100%;
    object-fit: cover;
  }
`
